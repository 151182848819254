<template>
  <div class="app-context util-page">
    <!-- <app-header
      :headerOption="headerOption"
      ref="appheader"
      @change-pw-done="changePwDone"
    ></app-header> -->
    <div class="context account">
      <div class="title">
        <div class="img-wrap">
          <img src="@/assets/images/ck_account.svg" />
        </div>
        <h3>비밀번호 변경안내</h3>
      </div>
      <div class="info">
        <div class="article">
          <h5>주요내용</h5>
          <p>
            비밀번호를 5회 이상 잘못입력하였습니다.
          </p>
          <p>비밀번호를 변경해야만 서비스를 이용할 수 있습니다.</p>
        </div>
        <div class="article">
          <h5>관련법령</h5>
          <p>
            정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조
            (개인정보의파기) 정보통신망 이용촉진 및 정보보호 등에 관한 법률
            시행령 제 16조(개인정보파기 등)
          </p>
        </div>
      </div>
      <div class="mention">
        <p></p>
        <p></p>
      </div>
      <div class="btn-wrap">
        <button type="button" class="active" @click="requireChangePw()">
          비밀번호 변경
        </button>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>
<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
/*     AppHeader, */
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      isChanged: false,
    };
  },
  created() {},
  mounted() {
    /** 
    this.$nextTick((_) => {
      this.$refs.appheader.callLoginPop(sessionStorage.getItem("destinedURL"), "fixing");
    });
     */
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    changePwDone() {
      this.isChanged = true;
    },
    requireChangePw() {
      // this.$refs.appheader.callPwOwnCheckPopup();
      const sendAppheaderItem = {
        method: "callPwOwnCheckPopup",
        item: "",
      };
      this.$emit("send-app-header-method", sendAppheaderItem);
    },
  },
  beforeDestroy() {
    if (!this.isChanged) {
      //console.log("상태 변경없이 페이지 이탈함");
      // this.$refs.appheader.clearLocalStorage();
      // this.$refs.appheader.initLogin();
      const sendAppheaderItem = {
        method: "clearLocalStorage",
        item: "",
      };
      this.$emit("send-app-header-method", sendAppheaderItem);

      const sendAppheaderItem2 = {
        method: "initLogin",
        item: "",
      };
      this.$emit("send-app-header-method", sendAppheaderItem2);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/account";
.context {
  .auth-notice {
    h3 {
      font-family: "SUIT";
      font-size: 26px;
      line-height: 42px;
      margin: 0 0 30px 0;
      color: #474747;
      b {
        color: #146f83;
        font-family: "SUIT";
      }
    }
  }
  .law {
    margin: 0 0 30px 0;
    .item {
      display: flex;
      border: 0px solid #e8e8e8;
      border-width: 1px 0 0 0;
      align-items: center;
      &:last-of-type {
        border-width: 1px 0 1px 0;
        margin: 0;
      }

      h5.i-title {
        font-family: "SUIT";
        font-weight: normal;
        color: #474747;
        background: #f8f8f8;
        min-width: 205px;
        margin: 0 20px 0 0;
        padding: 0 0 0 15px;
        line-height: 87px;
        font-size: 19px;
      }
      p {
        font-family: "SUIT";
        background: #fff;
        width: calc(100% - 225px);
        line-height: 29px;
      }
    }
  }
  p.notice {
    font-family: "SUIT";
    margin: 0 0 30px 0;
  }
  .restrict-btn-wrap {
    button {
      padding: 0;
      border: 0;
      background: #146f83;
      width: 145px;
      font-family: "SUIT";
      height: 54px;
      line-height: 54px;
      font-size: 22px;
      color: #fff;
      cursor: pointer;
    }
  }
}
.info {
  background-color: whitesmoke !important;
}
</style>
